import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"
import Select from 'react-select'


const SelectField = ({name, required, placeholder, values, handlechange, fieldClass, label, grpmd, componentprops}) => {
  var selectvalues = []
  {values.map(val =>selectvalues.push({ value:val, label: val } ))}
  //console.log("selectvaluesselectvalues", selectvalues)
  return (
  
  <Form.Group  md={grpmd} controlId={`validation${name}`} className="select-option form-group dropdown">
    { label
      ? <Form.Label className="form-label">{label}{required ? '*' : ''}</Form.Label>
      : ''

    }
    {selectvalues.length > 0 && 
    <Select placeholder={placeholder} defaultValue={{ label: selectvalues.length > 0 && selectvalues[0].label, value: selectvalues.length > 0 && selectvalues[0].value }} name={name} onChange={handlechange} required className="react-select"  classNamePrefix="react-select" searchable={false} options={selectvalues} values={[]} />    
    }
    {/* <Form.Control
		className={fieldClass}
		required={required}
		name={name}
		onChange={handlechange}
		as="select"
    value={componentprops ? componentprops[name] : ''}
		>
		{values.map(val =><option value={val} key="">{val}</option>)}
    </Form.Control> */}
  </Form.Group>
  )
}

export default SelectField;