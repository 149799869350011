import React from 'react'
import { Form, Button, Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"

const ButtonField = ({type, name, value, fieldClass, step,handlechange,handlechange2, noCopyRight}) => (
	<>
		<Button variant="" type={type} className={`send-btn btn btn-primary ${fieldClass}`}>
			{name}
			<i className="icon-right"></i>
		</Button>
		{!noCopyRight &&
			<span className="submit-text">
			By clicking Submit you agree to our <Link to="/terms-and-conditions/"> Terms &amp; Conditions </Link> and <Link to="/privacy-policy/">  Privacy Policy.</Link>
			</span>
		}
		{noCopyRight &&
			<span className="submit-text">
			Or email us at <a href="mailto:customerservice@mcconeproperties.com">customerservice@mcconeproperties.com</a>.
			</span>
		} 
	
	</>
);

export default ButtonField;