import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const TextAreaField = ({name, rows, placeholder, required, handlechange, fieldClass, label, grpmd, maxLength}) => (
  <Form.Group className="form-group custom-float text-area" controlId={"validation" + name}>
    { label
      ? <Form.Label className='form-label'>{label}{required ? '*' : ''}</Form.Label>
      : ''
    }
    <Form.Control
      className={fieldClass}
      name={name}
      required={required}
      as="textarea"
      rows={rows}
      // maxLength={maxLength ? maxLength : 0}
      onChange={handlechange}
      placeholder={placeholder} 
    />
  </Form.Group>
);

export default TextAreaField;